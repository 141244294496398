import { PASSWORD_MIN_LENGTH } from '@/domain/constants'
import {RegistrationErrors} from '../register/RegistrationErrors'
import i18n from '@/i18n'
export default class Credentials {
    public firstName = ''
    public secondName = ''
    public thirdName = ''
    public password = ''
    public password2 = ''
    public showpassword = false
    public showpassword2 = false
    public errors = new RegistrationErrors()
    public rules = {
        required: (value: string) => value.length > 0 || i18n.t('messages.errors.required_field'),

        passwordMinLength: () => this.password.length >= PASSWORD_MIN_LENGTH || i18n.t('messages.errors.password_min_len'),

        passwordMatch: (passwordConfirmation: string) => {
            this.passwordConfirmed = this.password === passwordConfirmation
            return this.passwordConfirmed || i18n.t('messages.errors.passwords_dont_match')
        }
    }

    private passwordConfirmed = false

    public allowedToRegister() {
        return  this.firstName.length > 0 &&
                this.secondName.length > 0 &&
                this.password.length >= PASSWORD_MIN_LENGTH &&
                this.passwordMatch()
    }

    public getRequestBody() {
        return {
            phone: localStorage.getItem('username'),
            password: this.password,
            first_name: this.firstName,
            last_name: this.secondName,
            third_name: this.thirdName
        }
    }

    public updateErrors() {
        this.errors.name = []
        this.errors.surname = []
        this.errors.password = []
        this.errors.password2 = []

        if (this.firstName.length < 1) this.errors.name.push(i18n.t('messages.errors.field_required_to_fill') as string)

        if (this.secondName.length < 1) this.errors.surname.push(i18n.t('messages.errors.field_required_to_fill') as string)

        if (this.password.length < PASSWORD_MIN_LENGTH) {
            this.errors.password.push(i18n.t('messages.errors.password_six') as string)
        }
        if (!this.passwordMatch()) {
            this.errors.password2.push(i18n.t('messages.errors.passwords_dont_match') as string)
        }
    }

    public clearNameErrors() {
        this.errors.name = []
    }

    public clearSurnameErrors() {
        this.errors.surname = []
    }

    public clearPasswordErrors() {
        this.errors.password = []
        this.errors.password2 = []
    }

    private passwordMatch() {
        return (this.password === this.password2 && this.password2 !== '')
    }
}