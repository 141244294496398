import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import { saveAuthHeaderToCookie, clearCookies } from '@/domain/constants'
import { isLoggedIn } from '@/domain/constants'
import router from '@/router'

const states = {
    isSignedIn: isLoggedIn()
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.SET_SIGNED_IN](state, value: boolean) {
        state.isSignedIn = value
    }
}

const actions: ActionTree<IState, any> = {
    [ActionNames.ON_SIGN]({ commit }, { token, refreshToken }) {
        saveAuthHeaderToCookie(token, refreshToken)
        commit(MutationNames.SET_SIGNED_IN, true)
    },

    [ActionNames.LOG_OUT]({ commit }, {returnroute}) {
        commit(MutationNames.SET_SIGNED_IN, false)
        clearCookies()
        if (returnroute) {
            router.push({name: returnroute}).catch(err => {/* */})
        } else {
            router.push({name: 'signin'}).catch(err => {/* */})
        }

    }
}

const getters: GetterTree<IState, any> = {
    [GetterNames.IS_SIGNED_IN](state): boolean {
        return state.isSignedIn
    }
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters
}

export default auth
