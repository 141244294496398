export class RegistrationErrors {
    public name: string[] =  []
    public surname: string[] = []
    public password: string[] = []
    public password2: string[] = []

    constructor() {
        this.name = []
        this.surname = []
        this.password = []
        this.password2 = []
    }

    public clearAll() {
        this.name = []
        this.surname = []
        this.password = []
        this.password2 = []
    }
}