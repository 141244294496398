export default {
    title: "Регистрация",
    login: "Введите Ваши данные чтобы продолжить, либо | Войдите",
    name: "Имя",
    surname: "Фамилия",
    thirdName: "Отчество",
    password: "Пароль для входа",
    passwordrepeat: "Подтвердите пароль",
    agreement: "Нажимая кнопку | вы соглашаетесь с | 'Пользовательским соглашением'",
    phone: {
        countrySelectorLabel: "Код страны",
        countrySelectorError: "Не выбрано",
        phoneNumberLabel: "Номер телефона",
        example: "Пример: "
    }
}