<template>
    <div class="calibre" id="calibre_link-0">
        <p class="block_">Пользовательское соглашение ---</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_2">Дата публикации:&nbsp;17 апреля 2019 года</p>
        <p class="block_2">г. Нур-Султан</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_3">Настоящее пользовательское соглашение (далее &ndash; Соглашение) в соответствии с положениями ст. 447 ГК РК является публичной офертой ТОО «<span lang="en">EGISTIC</span>» (далее &ndash; Администрация), и адресовано любому дееспособному физическому лицу, индивидуальному предпринимателю или юридическому лицу на изложенных ниже условиях.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_3">Лицо, совершившее акцепт оферты, признается Пользователем, заключившим Соглашение на следующих условиях:</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">Термины и определения:</p>
        <p class="block_5"><b class="calibre1">Сервис</b>&nbsp;&ndash; платформа <span lang="en">EGISTIC</span>, предназначенный для управления сельскохозяйственным производством. Состоит из сайта, адаптированного под мобильные устройства (телефон и ли планшет) принадлежит Администрации.<br class="calibre2" /><b class="calibre1">Сайт</b> &ndash; часть Сервиса, расположенная в сети Интернет по адресу https://www.<span lang="en">egistic</span>.<span lang="en">kz</span>/ (включая поддомены по адресу *.<span lang="en">egistic</span>.<span lang="en">kz</span>).</p>
        <p class="block_5"><b class="calibre1">Пользователь</b> &ndash; зарегистрированное физичексое или юридиское лицо в кабинете пользователя на сайте https://www.<span lang="en">egistic</span>.<span lang="en">kz</span>/</p>
        <p class="block_5"><b class="calibre1">Кабинет пользователя</b>&nbsp;&ndash; учетная запись Пользователя на Сервисе.</p>
        <p class="block_5"><b class="calibre1">Производственные данные</b>&nbsp;&ndash; информация, материалы, документы и иные объекты, загружаемые Пользователем на Сервисе, а также данные, генерируемые Сервисом для Пользователя в результате использования функционала Сервиса.</p>
        <p class="block_5"><b class="calibre1">Руководство</b>&nbsp;&ndash; комплекс данных, содержащих информацию о задачах и функциях Сервиса, расположенный на Сайте.</p>
        <p class="block_5"><b class="calibre1">Регистрация</b>&nbsp;&ndash; регистрация на Сервисе. Является безусловным и безоговорочным принятием условий Соглашения.</p>
        <p class="block_5"><span class="text_">Заказ</span><span class="text_1"> (получить данные) &mdash; услуга по предоставлению данных кадастрового номера участка на сайте </span><a href="https://www.egistic.kz/" class="calibre3"><span class="text_2">https://www.</span><span lang="en" class="text_2">egistic</span><span class="text_2">.</span><span lang="en" class="text_2">kz</span><span class="text_2">/</span></a></p>
        <p class="block_5"><b class="calibre1">Администрация</b> &ndash; руководство ТОО «<span lang="en">EGISTIC</span>»</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_6">&nbsp;</p>
        <p class="block_6">&nbsp;</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">1. Предмет</p>
        <p class="block_5">1.1. Администрация предоставляет Пользователю открытую неисключительную лицензию на использование Сервиса.</p>
        <p class="block_5">1.2. <span class="text_1">Администрация оказывает услуги согласно заказу Пользователя в соответствии с действующим прейскурантом, опубликованным на сайте, а Пользователь производит оплату и принимает услугу в соответствии с условиями настоящего Договора.</span></p>
        <p class="block_5">1.2. Пользователь использует Сервис согласно условиям Соглашения и Руководства.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">2. Регистрация и Аккаунт</p>
        <p class="block_5">2.1. Для использования Сервиса Пользователю необходимо совершить Регистрацию, пройдя процедуру оформления в кабинете пользователя.</p>
        <p class="block_5">2.2. Все данные Пользователя при регистрации обрабатываются в том виде, в котором они были получены от Пользователя.</p>
        <p class="block_5">2.3. После прохождения регистрации Пользователь получает доступ к кабинету пользователя.</p>
        <p class="block_5">2.4. Пользователь вправе предоставлять третьим лицам, в том числе своим сотрудникам, удаленный доступ для работы в Сервисе.</p>
        <p class="block_5">2.5. Любое действие, совершенное с использованием кабинета пользователя, считается действием, совершенным Пользователем или его уполномоченным лицом и устанавливает обязанности и ответственность для Пользователя в отношении таких действий, включая ответственность за нарушение настоящего Соглашения и (или) действующего законодательства.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">3. Условия использования</p>
        <p class="block_5">3.1. Пользователь вправе использовать Сервис в соответствии с положениями Соглашения и действующего законодательства Республики Казахстан.</p>
        <p class="block_5">3.2. Пользователь вправе с помощью Сервиса осуществлять любые действия, связанные с функционированием Сервиса, в соответствии с его назначением.</p>
        <p class="block_5">3.3. Администрация оказывает услуги, связанные с Сервисом, на возмездной основе. Такие отношения будут регулироваться счетом на оплату.</p>
        <p class="block_5">3.4. Администрация оказывает Пользователю консультационную и техническую поддержку при использовании Сервиса. Объем и пределы поддержки, сроки ответов на запросы, каналы коммуникации, определяются Администрацией в одностороннем порядке.</p>
        <p class="block_5">3.5. Сервис интегрирован с различными ресурсами третьих лиц, в том числе ресурсами аналитики, погоды, рассылок, данными спутников. </p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">4. Размещение Заказа</p>
        <p class="block_5">4.1. Заказ услуги осуществляется Пользователем через кабинет пользователя, на сайте https://www.<span lang="en">egistic</span>.<span lang="en">kz</span>/</p>
        <p class="block_5">4.2. Утвердив Заказ и дату оказания услуги, Пользователь получает счет на оплату.</p>
        <p class="block_5">4.3. Пользователь несет ответственность за содержание и достоверность информации, предоставленной при размещении Заказа.</p>
        <p class="block_5">4.4. Все информационные материалы, представленные на сайте https://www.<span lang="en">egistic</span>.<span lang="en">kz</span>/, носят справочный характер и не могут в полной мере передавать достоверную информацию об определенных свойствах и характеристиках. В случае возникновения у Пользователя вопросов, касающихся сроков и характеристик услуг, перед размещением Заказа ему необходимо обратиться за консультацией по номеру 8&nbsp;747&nbsp;227 5081 или послать запрос на адрес электронной почты <span lang="en">info</span>@<span lang="en">egistic</span>.<span lang="en">kz</span>.</p>
        <p class="block_7">&nbsp;</p>
        <p class="block_4">5.Сроки исполнения Заказа</p>
        <p class="block_5">5.1. Срок, в который Администрация обязуется исполнить Заказ, составляет от 3 часов. Срок исполнения Заказа зависит от срока заказа, установленного Пользователем. Срок исполнения Заказа в исключительных случаях может быть оговорен с Покупателем индивидуально в зависимости от характеристик и количества заказанной услуги. В случае отсутствия части Заказа на платформе, в том числе по причинам, не зависящим от последнего, Администрация вправе аннулировать указанный Заказ Пользователя. Администрация обязуется уведомить Пользователя об изменении даты исполнения Заказа путем направления сообщения на электронный адрес, указанный при регистрации в кабинете пользователя.</p>
        <p class="block_5">5.2. Заказ считается исполненным в момент его передачи Пользователю в кабинете пользователя. </p>
        <p class="block_5">5.3. Стоимость и условия оказания услуги по Заказу Пользователя уточняет на сайте https://www.<span lang="en">egistic</span>.<span lang="en">kz</span>/.</p>
        <p class="block_5">5.4. В случае предоставления Пользователем недостоверной информации о его контактных данных или составе Заказа, Администрация за ненадлежащее исполнение Заказа ответственности не несет.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">6. Оплата Заказа</p>
        <p class="block_5">6.1. Оплата исполненного Заказа по выбору Пользователя может, осуществляется:</p>
        <p class="block_5">1) путем перечисления денежных средств на расчетный счет Администрации;</p>
        <p class="block_5">2) путем перечисления Покупателем денежных средств в режиме онлайн через систему электронных платежей следующими платежными средствами:</p>
        <p class="block_5"><span lang="en">- </span>кредитные<span lang="en"> </span>карты<span lang="en"> (VISA,EuroCard/MasterCard);</span></p>
        <p class="block_5">- электронная наличность (WebMoney, Яндекс.Деньги, Qiwi и пр.).</p>
        <p class="block_5">6.2. Цена на оказание услуг отображается на сайте <a href="https://www.egistic.kz/" class="calibre3"><span class="text_2">https://www.</span><span lang="en" class="text_2">egistic</span><span class="text_2">.</span><span lang="en" class="text_2">kz</span><span class="text_2">/</span></a>. В случае изменения цены на оказание услуг, Администрация обязуется в кратчайшие сроки проинформировать Пользователя о таком изменении. Пользователь вправе подтвердить либо аннулировать Заказ. В случае отсутствия оплаты с Пользователем Заказ считается аннулированным в течение 2 (двух) календарных дней с момента размещения.</p>
        <p class="block_5">6.3. Цены, указанные на сайте, могут быть изменены Администрацией в одностороннем порядке без уведомления Пользователя.</p>
        <p class="block_5">6.4. Оплата Покупателем самостоятельно размещенного Заказа на сайте означает согласие с Условиями настоящего Пользовательского соглашения. День оплаты Заказа является датой заключения Пользовательского соглашения между Администрацией и Пользователем.</p>
        <p class="block_5"> </p>
        <p class="block_4">7. Отмена заказа и возврат средств</p>
        <p class="block_5">7.1. В случае оказания услуг Администрацией ненадлежащего качества, Пользователь имеет право на повторное оказание услуги на безвозмездной основе или возврат средств на расчетный счет Пользователя, использованный при оплате, в течение 3-5 банковских <span class="calibre4">дней  в</span> полном размере. </p>
        <p class="block_5">3.6. Услуга оказывается на основе космических снимков, полученных со спутников, поэтому необходимо учитывать облачность, при которой снижается качество снимков. В случае облачности на снимке участка более 15% от площади участка, услуга считается не оказанной, Пользователь может выбрать другую дату предоставления услуги.</p>
        <p class="block_5">7.2. Оплата за оказание услуг надлежащего качества, приобретенные Пользователем, обмену на другую дату и возврату не подлежат.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">4. Производственные данные и персональные данные</p>
        <p class="block_5">4.1. Все Производственные данные принадлежат Пользователю.</p>
        <p class="block_5">4.2. Администрация обеспечивает базовую информационную безопасность Производственных данных.</p>
        <p class="block_5">4.3. Администрация не вправе передавать Производственные данные третьим лицам за исключением следующих случаев:</p>
        <p class="block_5">a) получение соответствующего распоряжения от Пользователя;</p>
        <p class="block_5">b) получения запроса от правоохранительных или судебных органов.</p>
        <p class="block_5">4.4. Обработка и сбор персональных данных, предоставляемых Пользователем, осуществляется исключительно в рамках исполнения настоящего Соглашения и регулируется Политикой конфиденциальности.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">5. Интеллектуальная собственность</p>
        <p class="block_5">5.1. Администрация является правообладателем Сервиса и его составных частей (код, дизайн, Сайт, и т.д.).</p>
        <p class="block_5">5.2. Неисключительная лицензия ограничена «правом на использование», и ни одно из положений Соглашения не означает передачи Пользователю исключительного права на Сервис.</p>
        <p class="block_5">5.3. Неисключительная лицензия предоставляется на срок действия Соглашения и не ограничена конкретной территорией.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">6. Запрещенные действия</p>
        <p class="block_5">Пользователь не вправе:</p>
        <p class="block_5">6.1. Использовать Сервис и Руководство способами, прямо не предусмотренными Соглашением и (или) действующим законодательством.</p>
        <p class="block_5">6.2. Предоставлять доступ к кабинету на возмездной основе третьим лицам.</p>
        <p class="block_5">6.3. Изменять, настраивать, переводить или создавать производные продукты, основанные на Сервисе и элементах Руководства, а также интегрировать Сервис или элементы Руководства в другие результаты интеллектуальной деятельности без письменного согласия Администрации.</p>
        <p class="block_5">6.4. Предпринимать попытки обойти технические ограничения, установленные Сервисом.</p>
        <p class="block_5">6.5. Декомпилировать, дизассемблировать, дешифровать и производить иные действия с исходным кодом Сервиса.</p>
        <p class="block_5">6.6. Загружать фиктивные данные, создавать искусственную нагрузку на Сервис.</p>
        <p class="block_5">6.7. Использовать ботов (специальная программа, выполняющая автоматически и/или по заданному расписанию какие-либо действия через интерфейсы, предназначенные для Пользователя).</p>
        <p class="block_4">7. Приостановление доступа. Удаление Аккаунта</p>
        <p class="block_5">7.1. Администрация вправе приостановить реализацию права Пользователя на использование функционала Сервиса в случае:</p>
        <p class="block_5">a) Выявления нарушения Пользователем положений Соглашения.</p>
        <p class="block_5">b) Выявления нарушения Пользователем положений действующего законодательства Республики Казахстан.</p>
        <p class="block_5">c) Получения от третьих лиц претензии о нарушении Пользователем их прав.</p>
        <p class="block_5">d) Получения соответствующих требований от государственных органов.</p>
        <p class="block_5">7.2. Пользователь обязуется самостоятельно предпринять все действия для прекращения действий, вызвавших приостановку доступа.</p>
        <p class="block_5">7.3. Администрация вправе удалить Аккаунт Пользователя:</p>
        <p class="block_5">a) При получении соответствующего требования от Пользователя.</p>
        <p class="block_5">b) В случае не устранения Пользователем причин, повлекших приостановление доступа в течение более чем 20 дней.</p>
        <p class="block_5">c) В случае неоднократного (более 3 раз) совершения действий, вызывающих приостановление доступа.</p>
        <p class="block_5">d) В случае если Пользователь не использует Аккаунт более чем 6 месяцев подряд и не отвечает на запрос Администрации о планах по дальнейшему использованию Сервиса в течение более чем 15 дней.</p>
        <p class="block_4">8. Права и Обязанности Администрации</p>
        <p class="block_5">8.1. Администрация вправе:</p>
        <p class="block_5">8.1.1. Изменять, дорабатывать и обновлять Сервис в одностороннем порядке.</p>
        <p class="block_5">8.1.2. Ограничивать использование отдельных функций Сервиса для всех или отдельных категорий Пользователей, ограничить размещение определенной информации.</p>
        <p class="block_5">8.2. Администрация обязана:</p>
        <p class="block_5">8.2.1. Возвращать Пользователю доступ к кабинету в случае его взлома, утери или смены логина/пароля при наличии соответствующего запроса от Пользователя и информации, позволяющей однозначно идентифицировать принадлежность кабинета пользователю.</p>
        <p class="block_5">8.2.2. Уведомлять Пользователя об изменениях в Сервисе по электронной почте или всплывающим окном в Сервисе.</p>
        <p class="block_5">8.2.3. Предоставлять право использования Программы ежедневно и круглосуточно, за исключением времени проведения профилактических мероприятий, составляющих не более 48 часов в месяц.</p>
        <p class="block_5">8.2.4. При наличии технической возможности устранять возможные программные сбои в работе Сервиса.</p>
        <p class="block_4">9. Ограничение ответственности</p>
        <p class="block_5">9.1. Пользователь принимает на себя все риски, связанные с использованием Сервиса.</p>
        <p class="block_5">9.2. Ни при каких обстоятельствах Администрация или ее представители не несут ответственность перед Пользователем или перед любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, вызванный в связи с использованием Сервиса.</p>
        <p class="block_5">9.4 Администрация не гарантирует, что Сервис соответствуют требованиям Пользователя, будет предоставляться непрерывно, быстро, надежно и без ошибок, а качество какого-либо продукта, услуги, информации и пр., полученных с использованием Сервиса, будет соответствовать ожиданиям Пользователя.</p>
        <p class="block_5">9.5. Любые информацию и/или материалы (в том числе загружаемое ПО, какие-либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием Сервиса, Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за возможные последствия использования указанных информации и/или материалов.</p>
        <p class="block_5">9.6. Администрация не несет ответственности за любые виды убытков, произошедшие вследствие использования Пользователем Сервиса или отдельных частей/функций Сервиса.</p>
        <p class="block_5">9.7. Администрация не несет ответственности за любые действия Пользователя при использовании Сервиса.</p>
        <p class="block_5">9.8. Администрация не отвечает за последствия, вызванные утерей или разглашением Пользователем своих данных, необходимые для доступа к Сервису.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">10. Срок Соглашения</p>
        <p class="block_5">10.1. Соглашение действует с момент регистрации до момента удаления Аккаунта Пользователя.</p>
        <p class="block_5">10.2. После удаления Аккаунта Пользователь утрачивает возможность использовать функционал Сервиса.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_4">11. Разрешение споров и разногласий</p>
        <p class="block_5">11.1. Все споры или разногласия, возникающие между Сторонами в связи с исполнением Соглашения, разрешаются ими путем переговоров.</p>
        <p class="block_5">11.2. При не достижении Сторонами соглашения, споры и разногласия подлежат разрешению в претензионном порядке. Срок ответа на претензию &ndash; 30 дней.</p>
        <p class="block_7">&nbsp;</p>
        <p class="block_4">12. Заключительные положения</p>
        <p class="block_5">12.1. Принимая условия Соглашения, Пользователь дает согласие на получение дополнительной информации и информационных рассылок по указанным при регистрации данным.</p>
        <p class="block_5">12.2. Соглашение может быть изменено или дополнено Администрацией в любое время. Новая редакция Соглашения вступает в силу с момента ее размещения на Сервисе.</p>
        <p class="block_5">12.3. Продолжение использования Сервиса после внесения изменений и/или дополнений в Соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</p>
        <p class="block_5">12.4. Соглашение регулируется и толкуется в соответствии с законодательством РК, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством РК.</p>
        <p class="block_5">12.5. В случае, если какое-либо из положений Соглашения окажется ничтожным в соответствии с законодательством РК, остальные положения остаются в силе, а Соглашение будет исполняться Сторонами в полном объеме без учета такого положения.</p>
        <p class="block_5">12.6. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрацией агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не поименованных в Соглашении.</p>
        <p class="block_7">&nbsp;</p>
        <p class="block_4">13. Контактная информация и реквизиты Администрации</p>
        <p class="block_5">ТОО «<span lang="en">EGISTIC</span>»</p>
        <p class="block_5">БИН 180940005226</p>
        <p class="block_5">Почтовый адрес: 010000, Республика Казахстан, г. Нур-Султан, </p>
        <p class="block_5">пр. Кабанбай батыра 53, офис 13.4</p>
        <p class="block_8">Р/с KZ54856203105458567</p>
        <p class="block_8">АО «БанкЦентрКредит»</p>
        <p class="block_8">БИК KCJBKZKX</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_5">Электронная почта: <a href="mailto:info@egistic.kz" class="calibre3"><span lang="en" class="text_3">info</span><span class="text_3">@</span><span lang="en" class="text_3">egistic</span><span class="text_3">.</span><span lang="en" class="text_3">kz</span></a></p>
        <p class="block_5"><span lang="kk">Т</span>елефон: +7 <span lang="kk">747 227 50 81</span></p>
        <p class="block_9">&nbsp;</p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.block_ {
    background-color: #FFF;
    color: black;
    display: block;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
    text-transform: uppercase
    }
.block_1 {
    background-color: #FFF;
    color: black;
    display: block;
    font-family: serif;
    font-size: 0.75em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_2 {
    background-color: #FFF;
    color: black;
    display: block;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: right
    }
.block_3 {
    background-color: #FFF;
    color: black;
    display: block;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify;
    text-indent: 35.5pt
    }
.block_4 {
    background-color: #FFF;
    color: black;
    display: block;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center
    }
.block_5 {
    background-color: #FFF;
    color: black;
    display: block;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_6 {
    background-color: #FFF;
    display: block;
    font-family: "Arial", sans-serif;
    font-size: 0.75em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_7 {
    background-color: #FFF;
    color: black;
    display: block;
    font-family: serif;
    font-size: 0.75em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center
    }
.block_8 {
    display: block;
    font-size: 1em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_9 {
    color: black;
    display: block;
    font-family: serif;
    font-size: 0.75em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.calibre {
    background: #fff;
    color: #000;
    display: block;
    font-family: "Times New Roman", serif;
    font-size: 1em;
    margin-bottom: 0;
    padding-left: 5pt;
    padding-right: 5pt;
    margin-top: 0;
    }
.calibre1 {
    font-weight: bold
    }
.calibre2 {
    display: block
    }
.calibre3 {
    text-decoration: none
    }
.calibre4 {
    white-space: pre-wrap
    }
.text_ {
    color: #000;
    font-weight: bold
    }
.text_1 {
    color: #000
    }
.text_2 {
    color: #00F;
    text-decoration: underline
    }
.text_3 {
    color: black;
    text-decoration: underline
    }

@page {
    margin-bottom: 5pt;
    margin-top: 5pt
}


</style>
