

























































import { Vue, Component } from "vue-property-decorator"
import {Getter} from "vuex-class"
import {GetterNames} from "@/store/types"
import {apiUrl, openCrossOriginWebSite} from "@/domain/constants"
import {httpPost} from "@/util/http"
import i18n from "@/i18n"
import Cookies from 'js-cookie'

@Component
export default class PasswordSingUp extends Vue {
    @Getter(GetterNames.getSignViewData) private getSignViewData !: any
    private isLogining = false
    private passwordFormError = ''
    private showLoginPassword = false
    private showLoginPassword2 = false

    private username: any = ''
    private password: any = ''
    private password2: any = ''

    private smsVerifiedSuccess: any = false
    private alreadySmsVerified: any = false

    private type: any = ''

    private mounted() {
        this.username = localStorage.getItem('username') // this.$route.params.phone
        this.type = localStorage.getItem('type')
        if (this.$route.params.exist === 'success') {
            this.smsVerifiedSuccess = true
        }
        if (this.$route.params.exist === 'exist') {
            this.alreadySmsVerified = true
        }
    }

    private openRoute() {
        this.$router.push({name: 'signin'})
    }

    private checkPassword() {
        let counter = 0
        if (this.password === '' || this.password2 === '') {
            this.passwordFormError = 'Поле с паролем не должно быть пустым'
            counter++
        }
        if (this.password !== this.password2) {
            this.passwordFormError = 'Пароли не совпадают'
            counter++
        }
        if (this.password.length <= 5 || this.password2.length <= 5) {
            this.passwordFormError = 'Пароль должен содержать минимум 6 символов'
            counter++
        }
        return counter === 0
    }

    private signUp() {
        if (this.type === '2' || this.type === 2) {
            if (!this.checkPassword()) return
            httpPost({
                url: `${apiUrl}/reset_password/`,
                body: {
                    username: this.username,
                    new_password: this.password,
                    type: this.type
                },
                onSuccess: json => {
                    localStorage.removeItem('username')
                    localStorage.removeItem('type')
                    this.$router.push({name: 'signin', params: { exist: 'success' }})
                },
                onError: error => {
                    this.passwordFormError = i18n.t('headers.auth.signin.errors.google') as string
                    console.log(error)
                },
                doFinally: () => {
                    this.isLogining = false
                }
            })
        }
        if (!this.checkPassword()) return
        this.isLogining = true
        httpPost({
            url: `${apiUrl}/signup/new/`,
            body: {
                username: this.username,
                password: this.password
            },
            onSuccess: json => {
                console.log(json)
                if (json) {
                    localStorage.removeItem('username')
                    localStorage.removeItem('type')
                    this.$router.push({name: 'signin', params: { exist: 'success' }})
                } else {
                    this.passwordFormError = json.status
                }
            },
            onError: error => {
                this.passwordFormError = i18n.t('headers.auth.signin.errors.google') as string
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }
}
