




















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiUrl, openCrossOriginWebSite } from '@/domain/constants'
import { httpPost } from '@/util/http'
import Credentials from '@/domain/sign/Credentials'
import { MutationNames } from '@/store/types'
import PolicyView from './PolicyView.vue'
import i18n from '@/i18n'
import { Mutation } from 'vuex-class'

@Component({
    components: {
        PolicyView
    }
})
export default class SignUp extends Vue {
    @Mutation(MutationNames.changeNeedToConfirmMail) private changeNeedToConfirmMail !: any
    private registerCredentials = new Credentials()
    private isRegistering = false
    private needToConfirmMail = false
    private showRegisterPassword = false
    private isPolicyDialogShown = false

    @Watch('registerCredentials.firstName')
    private nameChanged() {
        this.registerCredentials.clearNameErrors()
    }

    @Watch('registerCredentials.secondName')
    private surnameChanged() {
        this.registerCredentials.clearSurnameErrors()
    }

    @Watch('registerCredentials.password')
    private passwordChanged() {
        this.registerCredentials.clearPasswordErrors()
    }

    private checkFormForErrors() {
        if (this.registerCredentials.allowedToRegister()) {
            return false
        } else {
            this.registerCredentials.updateErrors()
            return true
        }
    }

    private isSignDisabled(isSignInProgress: boolean, isAllowedToSign: boolean) {
        if (isSignInProgress) return true
        return !isAllowedToSign
    }

    private signUp() {
        if (this.isRegistering) return
        if (this.checkFormForErrors()) return
        this.isRegistering = true
        httpPost({
            url: `${apiUrl}/registration/`,
            isSecureRequest: false,
            body: this.registerCredentials.getRequestBody(),
            onSuccess: json => {
                this.goToSingIn()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => this.isRegistering = false
        })
    }

    private goToSingIn() {
        const route: any = {
            name: 'signin'
        }
        if (this.$route.params.redirect) this.$route.params.redirect = this.$route.params.redirect
        this.$router.push(route)
    }
}
